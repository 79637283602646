import { Component } from 'react';
import dateFormat from "dateformat";
import "./index.css"

class Clock extends Component {
  constructor() {
    super();
    this.state = { date: new Date() };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.setState({ date: new Date() }), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    console.log("update")
    return dateFormat(this.state.date.toLocaleString("en-US", { timeZone: "America/New_York" }), "h:MM:ss TT");
  }
}

function App() {
  return (
    <div className="App">
      <pre>
        <Clock></Clock>
      </pre>
    </div>
  );
}

export default App;
